import { store } from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class ProfitAndLossReportServices {
  
    getBalanceSheetReportFilterDatas(param) {
        const url = `api/account-report/balance-sheet-report/filter`
        const params = {
            'page-index': param.pageIndex,
            'branch': param.branch,
            'today': param.today,
            'month': param.month,
            'year': param.year,
            'from': param.from_date,
            'to': param.to_date,
            'page-reload': param.page_reload,
            'fiscal-year': param.fiscal_year,

        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }

    getUserAccessBranches() {
        const url = `api/sales/summary/user/branches`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }
  
  

}

export default new ProfitAndLossReportServices();