<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Balance Sheet
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Balance Sheet</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary">
      <div class="row mx-0">
        <div class="col-6 m-0 p-0">
          <table class="table table2 border-pl-right">
            <thead>
              <tr>
                <th class="wd-85p table-start-item">Particular</th>
                <th class="wd-15p text-right table-end-item">Cr. Amount</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="col-6 m-0 p-0">
          <table class="table table2">
            <thead>
              <tr>
                <th class="wd-85p table-start-item">Particular</th>
                <th class="wd-15p text-right table-end-item">Dr. Amount</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="balance-sheet" v-if="!loading && !error">
        <div class="row mx-0">
          <div class="col-6 m-0 p-0 border-pl-right">
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Capital</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(balanceSheetData.capital.total_blnc) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="cAccount in balanceSheetData.capital.accounts"
                  :key="cAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ cAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(cAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Reserve & Surplus</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ balanceSheetData.reserve_surplus }}
                  </th>
                </tr>
              </thead>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Loans</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(balanceSheetData.loan.total_blnc) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="lAccount in balanceSheetData.loan.accounts"
                  :key="lAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ lAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(lAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Current Liablities</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        balanceSheetData.currentLiabilities.total_blnc
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="clAccount in balanceSheetData.currentLiabilities
                    .accounts"
                  :key="clAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ clAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(clAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Profit/Loss</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(balanceSheetData.profitLoss.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="plAccount in balanceSheetData.profitLoss.accounts"
                  :key="plAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ plAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(plAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 m-0 p-0">
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Fixed Assets</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(balanceSheetData.fixedAssets.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="fAccount in balanceSheetData.fixedAssets.accounts"
                  :key="fAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ fAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(fAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Investments</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(balanceSheetData.investment.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="iAccount in balanceSheetData.investment.accounts"
                  :key="iAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ iAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(iAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Current Assets</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        balanceSheetData.curretnAssets.total_blnc
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="caAccount in balanceSheetData.curretnAssets.accounts"
                  :key="caAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ caAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ Math.abs(parseDigitForSlip(caAccount.closing_balance)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-6 m-0 p-0">
            <table class="table table2 border-pl-right">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    Difference In Opening Balance
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    <span v-if="balanceSheetData.dr_difference > 0">
                      {{ parseDigitForSlip(balanceSheetData.dr_difference) }}
                    </span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="col-6 m-0 p-0" v-if="balanceSheetData.cr_difference > 0">
            <table class="table table2">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item"></th>
                  <th class="wd-15p text-right table-end-item">
                    <span v-if="balanceSheetData.cr_difference > 0">
                      {{ parseDigitForSlip(balanceSheetData.cr_difference) }}
                    </span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-6 m-0 p-0">
            <table class="table table2 border-pl-right">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Total</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(balanceSheetData.dr_amount) }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="col-6 m-0 p-0">
            <table class="table table2">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Total</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(balanceSheetData.cr_amount) }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <!-- Balance sheep -->
      <div v-else-if="error" class="tx-center">{{ errorMessage }}</div>
      <div v-else-if="balanceSheetData.length == 0" class="tx-center">
        No records found.
      </div>
      <div v-else class="tx-center">Loading...</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      sessionYears: ["2021-22"],
      branches: [],
      filterParams: {
        pageIndex: "all",
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      errorMessage: "",
      loading: false,
      error: false,
      pageReload: "",
    };
  },
  computed: {
    ...mapGetters("balanceSheet", ["balanceSheetData"]),
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
    this.pageReload = true;
  },
  methods: {
    //print page
    print() {
      window.print();
    },
    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        page_reload: this.pageReload,
        fiscal_year: this.filterParams.fiscal_year,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        page_reload: this.pageReload,
        fiscal_year: this.filterParams.fiscal_year,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getBalanceSheetReportFilterDatas(params)
        .then((res) => {
          this.$store.commit("balanceSheet/setBalanceSheetData", res.data.data);
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
</style>